import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import React from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import UpsertModal from './UpsertModal';
import { Link } from 'react-router-dom';
import {
  faDraft2digital,
  faFirstdraft
} from '@fortawesome/free-brands-svg-icons';
import {
  faDraftingCompass,
  faQuestion
} from '@fortawesome/free-solid-svg-icons';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import { isNull } from 'lodash';

const Listing = () => {
  const SeoProjectsQuery = useQuery(
    ['seo-projects'],
    () => CallApi.get('/seo-project', false),
    { placeholderData: { data: [] } }
  );

  const columns = [
    {
      accessor: 'id',
      Header: (
        <>
          Actions
          <LocalStorageTooltip identifier="table_seo_projects_column_actions" />
        </>
      ),
      Cell: ({ cell }) => {
        const DeleteSeoProjectMutation = useMutation(
          ['delete-seo-project'],
          () => CallApi.delete('/seo-project/' + cell.value),
          {
            onSuccess: json => json?.status == 200 && SeoProjectsQuery.refetch()
          }
        );

        return (
          <>
            <UpsertModal
              iconOnly
              projectId={cell.value}
              isEditing={true}
              refreshListing={SeoProjectsQuery.refetch}
            />
            {/* <FontAwesomeIcon
              icon={
                DeleteSeoProjectMutation.isLoading ? 'spinner' : 'trash-alt'
              }
              className="ms-2 text-dark cursor-pointer"
              spin={DeleteSeoProjectMutation.isLoading}
              onClick={() =>
                confirm('Do you really want to delete this project?') &&
                DeleteSeoProjectMutation.mutate()
              }
            /> */}
          </>
        );
      }
    },
    {
      accessor: 'name',
      Header: (
        <>
          Project Name
          <LocalStorageTooltip identifier="table_seo_projects_column_project_name" />
        </>
      ),
      Cell: ({ cell }) => (
        <>
          {cell.value}{' '}
          <i className="text-plan">{cell.row.original.plan?.name}</i>
        </>
      )
    },
    {
      accessor: 'domain',
      Header: (
        <>
          Client
          <LocalStorageTooltip identifier="table_seo_projects_column_client" />
        </>
      ),
      Cell: ({ cell }) => {
        const FetchFaviconQuery = useQuery(
          ['fetch-favicon', cell.value],
          () => CallApi.get(`/fetch-favicon?url=${cell.value}`),
          {
            enabled: !cell.row.original.favicon && !SeoProjectsQuery.isFetching
          }
        );
        return (
          <>
            {cell.row.original?.favicon ? (
              <NameWithIcon
                name={cell.value}
                icon={cell.row.original.favicon}
              />
            ) : FetchFaviconQuery?.isLoading ? (
              <FontAwesomeIcon icon="spinner" spin />
            ) : (
              <NameWithIcon
                name={cell.value}
                icon={FetchFaviconQuery?.data?.url}
              />
            )}

            {FetchFaviconQuery.isLoading ? (
              <span className="ms-2">{cell.value}</span>
            ) : (
              <></>
            )}
          </>
        );
      }
    },
    {
      accessor: 'status',
      Header: (
        <>
          Status
          <LocalStorageTooltip identifier="table_seo_projects_column_status" />
        </>
      ),
      headerProps: { className: 'text-center' },
      cellProps: { className: 'text-center' },
      Cell: ({ cell }) => {
        const oneOfUrlNotWorking =
          !cell.row.original.seo_project_keywords?.every(keyword =>
            [
              keyword.check_status_200,
              keyword.check_working_ssl,
              keyword.check_working_indexable
            ].every(check => (isNull(check) ? false : check == true))
          );

        if (oneOfUrlNotWorking) {
          return (
            <LocalStorageTooltip
              identifier={'seo-project-status-warning'}
              customIcon={
                <FontAwesomeIcon
                  className="text-warning"
                  icon={['fas', 'exclamation-triangle']}
                  size="lg"
                />
              }
            />
          );
        }

        if (cell.value == 'active') {
          return (
            <LocalStorageTooltip
              identifier={'seo-project-status-active'}
              customIcon={
                <FontAwesomeIcon
                  icon={['fas', 'check']}
                  className="text-success"
                  size="lg"
                  title="Active"
                />
              }
            />
          );
        }

        if (cell.value == 'paused') {
          return (
            <LocalStorageTooltip
              identifier={'seo-project-status-paused'}
              customIcon={
                <FontAwesomeIcon
                  icon={['fas', 'circle']}
                  size="lg"
                  title="Paused"
                  className="text-warning"
                />
              }
            />
          );
        }

        if (cell.value == 'ended') {
          return (
            <LocalStorageTooltip
              identifier={'seo-project-status-ended'}
              customIcon={
                <FontAwesomeIcon
                  icon={['fas', 'times']}
                  size="lg"
                  title="In-Active"
                  className="text-danger"
                />
              }
            />
          );
        }

        if (cell.value == 'pending') {
          return (
            <LocalStorageTooltip
              identifier={'seo-project-status-pending'}
              customIcon={
                <FontAwesomeIcon
                  icon={['fas', 'cog']}
                  size="lg"
                  title="In-progress, we are working on this project"
                  className="text-info"
                  pulse
                />
              }
            />
          );
        }

        return (
          <FontAwesomeIcon
            icon={faQuestion}
            size="lg"
            title={`unknown status = ${cell.value}`}
            className="text-secondary"
          />
        );
      }
    },
    {
      accessor: 'managing_user.name',
      Header: (
        <>
          Contact Person
          <LocalStorageTooltip identifier="table_seo_projects_column_contact_person" />
        </>
      ),
      Cell: ({ cell }) => (
        <NameWithIcon
          icon={cell.row.original.managing_user?.avatar_url}
          name={cell.value}
        />
      )
    },
    {
      accessor: 'number_of_keywords',
      Header: (
        <>
          Keywords
          <LocalStorageTooltip identifier="table_seo_projects_column_keywords" />
        </>
      ),
      headerProps: { className: 'text-end' },
      cellProps: { className: 'text-end' }
    },
    {
      accessor: 'next_action_to_send.date_to_send_to_client',
      Header: (
        <>
          Next Report
          <LocalStorageTooltip identifier="table_seo_projects_column_next_report" />
        </>
      )
    },
    {
      accessor: 'next_action_report',
      Header: (
        <>
          Report to send
          <LocalStorageTooltip identifier="table_seo_projects_column_next_report_to_send" />
        </>
      )
    }
  ];

  return (
    <Card>
      <Card.Body className="m-3">
        <AdvanceTableWrapper
          sortable
          pagination
          columns={columns}
          data={SeoProjectsQuery?.data?.data || []}
        >
          <Row className="align-items-end g-2 row mb-3">
            <Col xs="auto">
              <AdvanceTableSearchBox table />
            </Col>
            <Col xs="auto">
              {SeoProjectsQuery.isRefetching ? (
                <FontAwesomeIcon
                  icon={'spinner'}
                  spin
                  title="Refreshing table..."
                />
              ) : null}
            </Col>
            <Col />
            <Col xs="auto">
              <Link
                to="/seo-project-drafts"
                className="btn btn-outline-primary btn-sm rounded-pill"
              >
                <FontAwesomeIcon icon={faFirstdraft} /> Drafts
              </Link>
            </Col>
            <Col xs="auto">
              <Link to={'/seo-project'}>
                <IconButton
                  icon={'plus'}
                  size="sm"
                  className="rounded-pill "
                  variant="outline-primary"
                >
                  Add Project
                </IconButton>
              </Link>
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            apiCallInProcess={
              SeoProjectsQuery.isFetching &&
              SeoProjectsQuery?.data?.data?.length == 0
            }
          />
          {/* <div className="mt-3">
            <AdvanceTableFooter
              rowCount={0}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div> */}
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default Listing;
